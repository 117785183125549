import { PATH_AUTH } from '../routes/paths';
export default function findFirstAuthorizedItem(list, userPermissions, userRole, userStatus, userEmail) {
    if (userStatus === false)
        return PATH_AUTH.emailVerification(userEmail);
    if (userPermissions?.length === 0 && userStatus === true)
        return PATH_AUTH.permissionsDenied;
    const authorizedItem = list?.find((item) => {
        if (userPermissions &&
            item?.permissionString &&
            userPermissions?.includes(item?.permissionString) &&
            userStatus === true)
            return true;
        if (userPermissions && item?.children !== undefined && item?.children?.length !== 0)
            return findFirstAuthorizedItem(item?.children, userPermissions, userRole, userStatus, userEmail);
        return false;
    });
    if (authorizedItem && authorizedItem?.path && userStatus === true)
        return authorizedItem?.path;
    return PATH_AUTH.login;
}
